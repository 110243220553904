<template>
  <div>
    <section>
      <v-row class="p-lg-5 ml-5 mr-5 ml-lg-15 mr-lg-15">
        <div class="text-center pb-5">
          <h3 class="section-subtitle pt-5 section3-title">Contact Us</h3>
        </div>
        <v-col sm="6" class="pt-5 text-center">
          <!-- <v-row class="text-center mb-3"
            ><h3><b>Company Name</b></h3></v-row
          > -->

          <!-- <v-row class="text-lg-center pl-lg-15 ml-lg-7 mr-lg-0">
            <v-col cols="2" sm="2">
              <v-icon large color="blue darken-2" class="mb-5">
                mdi-map-marker-radius
              </v-icon>
            </v-col>
            <v-col cols="10" sm="10">
              <p class="mb-1 text-left">
                A 1112-A, The Capital, Science City Rd,
              </p>
              <p class="text-left">Ahmedabad, Gujarat 380060</p>
            </v-col>
          </v-row> -->

          <!-- <v-row class="text-center mb-3 pl-lg-15 ml-lg-7">
            <v-col cols="2" sm="2">
              <v-icon large color="blue darken-2" class="mb-3">
                mdi-phone-classic
              </v-icon>
            </v-col>
            <v-col cols="10" sm="10">
              <p class="text-left mb-1">(255)555-3232</p>
            </v-col>
          </v-row> -->
          <v-row class="text-center mb-3 pl-lg-15 ml-lg-7">
            <v-col cols="2" sm="2">
              <v-icon large color="blue darken-2" class="mb-3">
                mdi-gmail
              </v-icon>
            </v-col>
            <v-col cols="10" sm="10">
              <p class="text-left mb-1">feedveiw.help@gmail.com</p>
            </v-col>
          </v-row>
          <v-row class="text-center mb-3 pl-lg-15 ml-lg-7">
            <v-col cols="2" sm="2">
              <v-icon large color="blue darken-2" class="mb-3">
                mdi-web
              </v-icon>
            </v-col>
            <v-col cols="10" sm="10">
              <p class="text-left mb-1">feedveiw.help@gmail.com</p>
            </v-col>
          </v-row>
        </v-col>
        <v-col sm="5" cols="12" class="ml-lg-15 ml-lg-0 mb-5">
           <v-form
    ref="form"
    v-model="valid"
    lazy-validation
  >
          <v-text-field
            label="First Name"
            solo
            class="pb-3"
            v-model="first_name"
             :rules="fnameRules"
             required
             @keydown="msg=false"
          ></v-text-field>

          <v-text-field
            label="Last Name"
            solo
            id="lname"
            class="pb-3"
            :rules="lnameRules"
            v-model="last_name"
            required
            @keydown="msg=false"
          ></v-text-field>

          <v-text-field
            label="Email"
            solo
            id="email"
            class="pb-3"
            :rules="emailRules"
            v-model="email"
            required
            @keydown="msg=false"
          ></v-text-field>

          <v-text-field
            label="Company Name"
            solo
            id="cname"
            class="pb-3"
            v-model="company_name"
            :rules="cnameRules"
            required
            @keydown="msg=false"
          ></v-text-field>
          <v-text-field
            label="Subject"
            solo
            id="subject"
            class="pb-3"
            v-model="subject"
           :rules="subjectRules"
            required
            @keydown="msg=false"
          ></v-text-field>

          <v-textarea
            label="Massege"
            solo
            name="input-7-4"
            v-model="we_can_help"
            :rules="messageRules"
            required
            @keydown="msg=false"
          ></v-textarea>

          <b-button class="button-bg-dark border-radius-8" @click="submitData">
            Submit
          </b-button>
          <!-- <p v-if="msg" style="color:green" class="mt-2"><strong> Successfully</strong></p> -->
           <div class="mt-3 alert alert-success alert-dismissible fade show" v-if="msg">
        <strong>Success!</strong> Your data has been sent successfully.
    </div>
           </v-form>
        </v-col>
      </v-row>
    </section>
  </div>
</template>
<script>
import { mapActions } from "vuex";
export default {
  name: "aboutus",
  data() {
    return {
      email: null,
      valid:true,
      first_name: null,
      last_name: null,
      company_name: null,
      subject: null,
      we_can_help: null,
      msg:false,
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+/.test(v) || 'E-mail must be valid',
      ],
      lnameRules: [
        v => !!v || 'LastName is required',
      ],
       fnameRules: [
        v => !!v || 'FirstName is required',
      ],
       cnameRules: [
        v => !!v || 'CompanyName is required',    
      ],
        subjectRules: [
        v => !!v || 'Subject is required',
      ],
      messageRules: [
        v => !!v || 'Message is required',
      ],
      
    };
  },
  methods: {
    ...mapActions(["sendContactUsData"]),
    submitData() {
         if (this.$refs.form.validate()) {
         this.sendContactUsData({
        first_name: this.first_name,
        last_name: this.last_name,
        company_name: this.company_name,
        subject: this.subject,
        email: this.email,
        we_can_help: this.we_can_help,
      }).then((res) => {
        console.log(res);
          this.msg=true;
              this.email= "";
       this.first_name= "";
       this.last_name="";
      this. company_name= "";
      this. subject= "";
      this. we_can_help= "";
     this.$refs.form.resetValidation()
      });
         }
         else{
           this.$refs.form.validate()
         }
    
     
     
    },
  },
};
</script>
